/* eslint-disable no-unused-expressions */
import React, { Component } from "react";
import './FacilityBookTabs.css';
import { apiCaller } from "../../../services/apiCaller";
import "./FacilityBookTabs.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import GuidlinesFAQ from "../components/TabGuidlinesFAQ";
import FacilityInfo from "../components/TabFacilityInfo";
import moment from "moment";
import ReactLoading from "react-loading";
import swal from "sweetalert";
import Swal from "sweetalert2";
import { error } from "jquery";

class FacilityBookTabs extends Component {

    constructor(props) {
        super(props);
        const defaultDate = new Date();
        this.state = {
            facilities: {},
            times: {},
            timeClicked: false,
            bookingDate: defaultDate.getDay() === 0 ? defaultDate.setDate(defaultDate.getDate() + 1) : defaultDate.getDay() === 6 ? defaultDate.setDate(defaultDate.getDate() + 2) : defaultDate,
            timeSlotLoading: false,
            bookedSlots: 0,
            quotaUsed: 0,
            totalPax: 0,
            invitedGuest: '',
            bookingNote: '',
            isLoading: false,
            user: JSON.parse(localStorage.getItem("userData"))
        };
    }

    componentDidMount() {
        this.getTimeSlot();
    }

    getTimeSlot = async (date) => {
        try {
            const response = await apiCaller.get(`/getTimeTable?facility_id=${this.props.data.id}&date=${date || moment(this.state.bookingDate).format("YYYY-MM-DD")}`);
            this.setState({
                times: response.data,
                timeSlotLoading: false,
                bookedSlots: 0
            });
        } catch (error) {
            console.error(error);
            return Swal.fire({
                icon: 'error',
                title: "Date Unavailable",
                text: 'Please Select Another Day',
            });
        }
    }

    toggleBooking(i) {
        let tempData = this.state.times;
        if (!tempData[i].bookable) {
            return false;
        }
        if (tempData[i].clicked) {
            this.setState(prevState => ({
                bookedSlots: prevState.bookedSlots - 1,
                quotaUsed:prevState.bookedSlots - 1,
            }));
            delete tempData[i].clicked;
        } else {
            tempData[i].clicked = true;
            this.setState(prevState => ({
                timeClicked: true,
                bookedSlots: prevState.bookedSlots + 1,
                quotaUsed: prevState.bookedSlots + 1
            }));
        }
        return this.setState({
            times: tempData
        });
    }

    handleChange = date => {
        const dateParam = date || new Date();
        this.setState({
            bookingDate: dateParam,
            timeSlotLoading: true
        });
        return this.getTimeSlot(moment(dateParam).format("YYYY-MM-DD"));
    };

    displayTimeSlotCard() {
        const timeSlotArray = Object.entries(this.state.times);
        const now = new Date();
        return timeSlotArray.length < 1 ? (
            <div className="mt-5 col-12 text-center">
                <h4 style={{ color: "#888888" }}>Sorry but we are unavailable on your selected date.</h4>
            </div>
        ) : (
            timeSlotArray.slice(0, -1).map((v, i) => {
                const timeValue = v[1].time;
                let bookableDate = this.state.times[i].bookable;
                if (((now.getHours() * 100) + now.getMinutes()) >= timeValue && now >= this.state.bookingDate) {
                    bookableDate = false;
                }
                return (
                    <div key={i} className={`col-6 col-md-3 col-lg-3 col-xl-4 p-2 time-slot `}>
                        <div onClick={() => this.toggleBooking(i)} className={`card time-slot-card ${bookableDate? "bg-available" : "bg-booked"} ${this.state.times[i].clicked ? "bg-success" : ""}`}>
                            <div className="card-body text-center">
                                {`${(v[1].time).toString().slice(0, -2)}:00`}<span className="d-block d-sm-inline"> - </span> <span className="d-block d-sm-inline">{`${(v[1].time + 100).toString().slice(0, -2)}:00`}</span>
                            </div>
                        </div>
                    </div>
                );
            })
        );
    }

    handleInputChange = (field) => (e) => {
        if (field === 'totalPax') {
            const val = parseInt(e.target.value, 10);
            const maxPax = this.props.data.max_pax;
            this.setState({
                [field]: isNaN(val) || val > maxPax ? "maxPax" : val
            });
            if(this.props.data.id === 2004 && e.target.value > 10){
                this.setState({quotaUsed: this.state.bookedSlots * this.props.data.deduction_rates});
            }
        } else {
            this.setState({
                [field]: e.target.value
            });
        }
    }

    prepareBookingData = () => {
        const bookedTimes = Object.values(this.state.times).filter(v => v.clicked).map(v => v.time);
        return {
            totalPax:this.state.totalPax,
            guests: this.state.invitedGuest.replace(/ /g, ''),
            bookingNote: this.state.bookingNote,
            times: bookedTimes,
            facility_id: this.props.data.id,
            booking_date: moment(this.state.bookingDate).format("YYYY-MM-DD"),
            location_id: this.props.data.location_id
        };
    }

    sendBookingData = async () => {
        if(this.state.totalPax === "maxPax"){
            return { status: false, msg:`Maximum pax for this facility is ${this.props.data.max_pax} ` };
        }
        
        const bookingData = this.prepareBookingData();
        if(bookingData.guests.length > 0){
            const invitedGuest = bookingData.guests;
            const emails = invitedGuest.split(",");
            const validEmails = emails.filter(email => {
                const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
                return emailRegex.test(email.trim());
            });
            if (validEmails.length !== emails.length) {
                return { status: false, msg: "Invalid email format. Please use a single email 'a@mail.com' or plural 'a@gmail.com,b@gmail.com' separated by comma format. No space allowed." };
            }
            
        }
        try {
            await apiCaller.post("/storeBooking", bookingData);
            return { status: true };
        } catch (err) {
            return { status: false, msg: err.response.data.message };
        }
    }

    submitBooking = async () => {
        this.setState({
            isLoading: true,
            timeSlotLoading: true
        });
        // await this.sendBookingData();
        const confirmBooking = await Swal.fire({
            title: "Are you sure?",
            text: `This booking will use ${this.state.quotaUsed} hour${this.state.quotaUsed > 1 ? "s" : ""} of your available quota`,
            icon: "warning",
            input: 'checkbox',
            inputPlaceholder: 'I read and agree with the guidelines',
            inputValue: 0,
            confirmButtonText: 'Yes <i class="fa fa-arrow-right"></i>',
            showCancelButton: true,
            cancelButtonColor: "red",
            inputValidator: (result) => !result && 'You need to agree!'
        });

        this.setState({
            isLoading: false,
            timeSlotLoading: false,
        });
        
        if (confirmBooking.isConfirmed) {
            const res = await this.sendBookingData();
            if (res.status) {
                swal("Nice", "Thank you, see you there!", "success").then(() => document.location.reload());
            } else {
                swal("Error", res.msg, "error");
            }
        }
    }

    getWeekdays = date => {
        const day = date.getDay();
        if (this.state.user.client_id === 1019310) {
            return true;
        }
        return day !== 0 && day !== 6;
    };

    render() {
        const facilityData = this.props.data;

        return (
            <>
                <div className="m-t-10">
                    {this.state.modalSuccess ? this.modalSuccessBooking() : ""}
                    <div>
                        <ul className="nav nav-tabs tab-line desktop-ui" id="myTab2" role="tablist">
                            <li className="nav-item px-0">
                                <a className="nav-link active text-center" id="home-tab2" data-toggle="tab" href="#line-home"
                                    role="tab" aria-controls="home" aria-selected="true">
                                    <span className="menu-icon mr-1">
                                        <i className="icon-placeholder mdi mdi-book-open "></i>
                                    </span>
                                    Booking</a>
                            </li>
                            <li className="nav-item px-0">
                                <a className="nav-link text-center" id="profile-tab2" data-toggle="tab" href="#line-profile"
                                    role="tab" aria-controls="profile" aria-selected="false">
                                    <span className="menu-icon mr-1">
                                        <i className="icon-placeholder mdi mdi-information "></i>
                                    </span>
                                    Facility Info</a>
                            </li>
                            <li className="nav-item px-0">
                                <a className="nav-link text-center" id="contact-tab3" data-toggle="tab" href="#line-contact"
                                    role="tab" aria-controls="contact" aria-selected="false">
                                    <span className="menu-icon mr-1">
                                        <i className="icon-placeholder mdi mdi-account-question "></i>
                                    </span>
                                    Guidelines & FAQ's</a>
                            </li>
                        </ul>

                        <ul className="nav nav-tabs tab-line text-center mobile-ui" id="myTab2" role="tablist">
                            <li className="nav-item col-4 px-0">
                                <a className="nav-link active" id="home-tab2" data-toggle="tab" href="#line-home"
                                    role="tab" aria-controls="home" aria-selected="true">
                                    <span className="menu-icon d-block lh-16">
                                        <i className="icon-placeholder mdi mdi-book-open "></i>
                                    </span>
                                    <span className="font-size-sm font-rg">Booking</span>
                                </a>
                            </li>
                            <li className="nav-item col-4 px-0">
                                <a className="nav-link" id="profile-tab2" data-toggle="tab" href="#line-profile"
                                    role="tab" aria-controls="profile" aria-selected="false">
                                    <span className="menu-icon d-block lh-16">
                                        <i className="icon-placeholder mdi mdi-information "></i>
                                    </span>
                                    <span className="font-size-sm font-rg">Info</span>
                                </a>
                            </li>
                            <li className="nav-item col-4 px-0">
                                <a className="nav-link" id="contact-tab3" data-toggle="tab" href="#line-contact"
                                    role="tab" aria-controls="contact" aria-selected="false">
                                    <span className="menu-icon d-block lh-16">
                                        <i className="icon-placeholder mdi mdi-account-question "></i>
                                    </span>
                                    <span className="font-size-sm font-rg">Guide</span>
                                </a>
                            </li>
                        </ul>
                        <div className="tab-content tab-container pt-5" id="myTabContent2">
                            <div className="tab-pane fade show active" id="line-home" role="tabpanel"
                                aria-labelledby="home-tab">
                                <div className="row m-0">
                                    <div className="col-lg-4 p-0 pr-md-4">
                                        <label className="d-block font-size-rg mb-0 font-sb">BOOK DATE</label>
                                        <label className="d-block font-size-sm font-rg opacity-06">Select the booking date.</label>
                                        <DatePicker
                                            className="form-control cstm-mb-4"
                                            selected={this.state.bookingDate}
                                            onChange={(date) => this.handleChange(date)}
                                            dateFormat="yyyy-MM-dd"
                                            filterDate={(date) => this.getWeekdays(date)}
                                            minDate={new Date()}
                                            maxDate={(new Date()).setDate((new Date()).getDate() + 90)}
                                        />
                                    </div>
                                    <div className="col-lg-8 p-0">
                                        <label className="d-block font-size-rg mb-0 font-sb">AVAILABLE TIME</label>
                                        <label className="d-block font-size-sm font-rg opacity-06">Pick the most suitable time for you.</label>
                                        <div className="row m-0">
                                            {
                                                this.state.timeSlotLoading ?
                                                    <ReactLoading type="bubbles" style={{
                                                        margin: "0 auto",
                                                        fill: "rgb(136, 136, 136)",
                                                        height: 69,
                                                        width: 69
                                                    }} /> :
                                                    this.displayTimeSlotCard()
                                            }
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <label>Total Hours : <b>{this.state.bookedSlots}</b></label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mb-2">
                                    <label className="d-block font-size-rg font-sb mb-0">TOTAL PAX</label>
                                    <input className="w-100 form-control" onChange={this.handleInputChange('totalPax')} placeholder={"Total Pax"} type="number"></input>
                                </div>
                                <div className="mb-2">
                                    <label className="d-block font-size-rg font-sb mb-0">BOOKING NOTE</label>
                                    <textarea className="w-100 form-control" onChange={this.handleInputChange('bookingNote')} placeholder="Weekly Report Meeting Agenda"></textarea>
                                </div>
                                <div className="border-top">
                                    <label className="d-block font-size-rg font-sb mb-0">INVITE GUESTS VIA EMAIL</label>
                                    <label className="d-block font-size-sm font-rg opacity-07">for multiple emails use comma (,) to separate them and do not use spaces between emails</label>
                                    <textarea className="w-100 form-control" onChange={this.handleInputChange('invitedGuest')} placeholder="john.doe@email.com,alex@gmail.com,alesha@gmail.com"></textarea>
                                </div>
                                <div className="w-100 text-right">
                                    <button onClick={() => this.submitBooking()} className={`btn btn-primary mt-3 font-size-rg w-100 ${this.state.bookedSlots < 1 ? "d-none" : "d-block"}`} disabled={this.state.isLoading ? "disabled" : ""}>
                                        {
                                            this.state.isLoading ? <div className="spinner-border text-secondary" role="status"><span className="sr-only">Loading...</span></div> : 'Book Now'
                                        }
                                    </button>
                                </div>
                            </div>
                            <FacilityInfo data={facilityData} />
                            <GuidlinesFAQ />
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default FacilityBookTabs;

